.detailScreen {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 150px;

    
}

.detailCard {
    display: flex;
    flex-flow: wrap;
    flex-direction: column;
    width: 500px;
    height: 225px;
    border-width: 1px;
    border-radius: 25px;
    border-style: solid;
    background-color: var(--card-color);
    color: white;
    text-decoration: none;
    font-size: 25px;
    align-items: flex-start;
    justify-content: space-around;
    padding:20px;
       
}

.detailCard > * {
    display: flex;
    flex-flow: nowrap;
    flex-direction: column;
    color:white;
}


